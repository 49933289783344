.bestmovie__container {
    max-width: calc(100% - 70px*2);
    padding: 0 70px 109px;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1279px) {
    .bestmovie__container {
        max-width: calc(100% - 30px*2);
        padding: 0 30px 9px;
    }
}

@media screen and (max-width: 767px) {
    .bestmovie__container {
        max-width: calc(100% - 10px*2);
        padding: 0 10px;
    }
}