.movies__bag {
	margin: 0 auto;
	display: grid;
	justify-content: center;
	justify-items: center;
}
.movies__list {
	margin: 0 auto;
	display: grid;
	justify-content: center;
	grid-template-columns: repeat(3, 360px);
	column-gap: 30px;
	row-gap: 32px;
	padding: 81px 0 10px;
}

@media screen and (max-width: 1279px) {
	.movies__list {
		grid-template-columns: repeat(2, 342px);
		column-gap: 24px;
		row-gap: 35px;
		padding: 80px 0 30px;
	}
}

@media screen and (max-width: 767px) {
	.movies__list {
		grid-template-columns: 300px;
		row-gap: 16px;
		padding: 49px 0 30px;
	}
}