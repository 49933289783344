.register {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    padding: 70px 0;
    max-width: 396px;
    width: 100%;
    align-self: center;
}

@media screen and (max-width: 1279px) {
    .register {
        padding: 232px 0;
    }
}

@media screen and (max-width: 767px) {
    .register {
        padding: 56px 0 30px;
        max-width: 260px;
        align-items: center;
    }
    .register__title {
        padding: 50px 0 80px;
    }
    .register__button {
        margin: 157px 0 14px;
        font-size: 12px;
        line-height: 15px;
        padding: 15px 0;
    }
    .register__question {
        font-size: 12px;
        line-height: 15px;
    }
    .register__link {
        padding-left: 6px;
    }
}

.register__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    margin: 0;
    padding: 40px 0;
}

.register__button {
    width: 100%;
    background: #000000;
    border-radius: 3px;
    margin: 69px 0 16px;
    padding: 13px 0 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    transition: opacity 0.5s linear;
    border: none;
}

.register__button:hover {
    opacity: 0.6;
}
.register__button_disebled {
    opacity: 0.6;
}

.register__question {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #A0A0A0;
    margin: 0;
    align-self: center;
}

.register__link {
    text-decoration: none;
    padding-left: 6px;
    color: #000000;
    cursor: pointer;
    transition: opacity 0.5s linear;
}

.register__link:hover {
    opacity: 0.6;
    text-decoration: underline 2px #000000;
    font-weight: 500;
}

.form {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
}

.form__data {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #A0A0A0;
    padding-bottom: 10px;
}

.form__input {
    background: #F9F9F9;
    border-radius: 8px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;

    outline: none;
    border: none;
    margin-bottom: 10px;

    padding: 15px;
    width: calc(100% - 15px*2);
}

.form__input-wrong {
    color: #FF4062;
}

.form__input-error {
    color: #FF4062;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    min-height: 10px;
    opacity: 1;
    /* opacity: 0; */
}