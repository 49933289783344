.header {
    max-width: calc(100% - 70px*2);
    padding: 18px 70px;
    display: flex;
    justify-content: space-between;
    background-color: #F3C1F8;
    position: relative;
}

@media screen and (max-width: 1279px) {
    .header {
        max-width: calc(100% - 30px*2);
        padding: 15px 30px;
    }
}

@media screen and (max-width: 767px) {
    .header {
        max-width: calc(100% - 14px*2);
        padding: 15px 14px;
    }
}

/* Ссылки в Header для незарегистрированного пользователя */
.header__box {
    display: flex;
    align-self: center;
    align-items: flex-end;
    margin: 0;
    padding: 0;
}

.header__link {
    transition: opacity 0.5s linear;
    cursor: pointer;
    margin: 0;
    padding: 0;
    border-radius: 3px;
    border: none;
    background: transparent;
    text-decoration: none;
    color: #000000;

}
.header__link:first-of-type {
    margin: 0 10px 0 0;
}

.header__link:hover {
    opacity: 0.6;
    background: #000000;
    color: #FFFFFF;
}
.header__link:focus {
    opacity: 0.6;
    background: #000000;
    color: #FFFFFF;
    border: none;
    outline: none;
}

.header__registration {
    text-decoration: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: right;

    margin: 0;
    padding: 8px 20px;
}

.header__autorization {
    text-decoration: none;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    margin: 0;
    padding: 8px 20px;
}

@media screen and (max-width: 767px) {
    .header__box {
        margin-top: 3px;
    }

    .header__autorization {
        font-size: 10px;
        padding: 5px 12px;
    }

    .header__registration {
        font-size: 10px;
        margin: 0 2px 0 0;
        padding: 5px 12px;
    }
}