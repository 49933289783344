.logo__link {
    transition: opacity 0.5s linear;
    cursor: pointer;
    text-decoration: none;
    border: none;
}

.logo__link:hover {
    opacity: 0.6;
}

.logo__image {
    margin: 0;
    padding: 0;
    width: 38px;
    height: 38px;
}

@media screen and (max-width: 1279px) {
    .logo__image {
        padding-top: 3px;
    }
}