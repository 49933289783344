.techs__content {
    background-color: #F5F5F5;
    padding: 100px 70px;
}

.techs__list {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-auto-rows: 1fr;
    list-style: none;
    padding: 90px 0 100px;
    margin: 0 auto;
}

.techs__subtitle {
    font-weight: 400;
    font-size: 50px;
    line-height: 58px;
    text-align: center;
    letter-spacing: -0.04em;
    padding: 0;
    margin: 0;
}

.techs__discription {
    max-width: 460px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.04em;
    padding: 26px 0 0;
    margin: 0;
}

.techs__block {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(7, 90px);
    column-gap: 10px;
    justify-content: center;
}

.techs__item {
    background-color: #E8E8E8;
    border: none;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: -0.04em;
    padding: 21px 0 22px;
    margin: 0;
}

@media screen and (max-width: 1279px) {
    .techs__content {
        padding: 90px 50px;
    }

    .techs__list {
        padding: 80px 0 83px;
    }

    .techs__discription {
        font-size: 12px;
        line-height: 18px;
        padding: 22px 0 0;
    }

    .techs__block {
        grid-template-columns: repeat(7, 84px);
    }

    .techs__item {
        font-size: 12px;
        line-height: 15px;
        padding-bottom: 21px;
    }
}

@media screen and (max-width: 767px) {
    .techs__content {
        background-color: #FFFFFF;
        padding: 70px 18px;
    }

    .techs__list {
        padding: 60px 0 50px;
    }

    .techs__subtitle {
        font-size: 30px;
        line-height: 36px;
    }

    .techs__discription {
        font-size: 11px;
        line-height: 16px;
        padding: 24px 0 0;
    }

    .techs__block {
        grid-template-rows: repeat(4, 1fr);
        grid-template-columns: repeat(2, 84px);
        gap: 10px;
    }
}