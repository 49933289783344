.promo {
    background-color: #F3C1F8;
    padding: 70px 70px 85px;
    position: relative;
}


.promo__image-web {
    width: 310px;
    height: 304px;
    position: absolute;
    top: 75px;
    right: 70px;
    margin: 0;
}

.promo__title {
    max-width: 640px;
    font-weight: 400;
    font-size: 50px;
    line-height: 58px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 0;
}

.promo__subtitle {
    max-width: 310px;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    opacity: 0.5;
    margin: 0;
    padding: 36px 0 155px;
}

.promo__link {
    outline: 1px solid #000000;
    border: none;
    border-radius: 3px;
    background-color: transparent;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding: 10px 20px;
    transition: opacity 0.5s linear;
    cursor: pointer;
    text-decoration: none;
    color: #000000;
}

.promo__link:hover {
    opacity: 0.6;
    background-color: #000000;
    color: #FFFFFF;
}
.promo__link:focus {
    opacity: 0.6;
    background-color: #000000;
    color: #FFFFFF;
}

@media screen and (max-width: 1279px) {
    .promo {
        padding: 140px 30px 75px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .promo__image-web {
        position: static;
        padding-bottom: 48px;
    }
    .promo__title {
        max-width: 680px;
        font-size: 40px;
        line-height: 50px;
        text-shadow: none;
        text-align: center;
    }
    .promo__subtitle {
        max-width: 260px;
        text-align: center;
        padding: 22px 0 92px;
    }

}

@media screen and (max-width: 767px) {
    .promo {
        padding: 70px 10px 30px;
    }
    .promo__image-web {
        width: 210px;
        height: 204px;
        padding-bottom: 46px;
    }
    .promo__title {
        max-width: 300px;
        font-size: 30px;
        line-height: 38px;
        letter-spacing: -0.04em;
    }
    .promo__subtitle {
        padding: 24px 0 80px;
    }
}