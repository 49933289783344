.login {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
    padding: 70px 0;
    max-width: 396px;
    width: 100%;
    align-self: center;
}

.login__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    margin: 0;
    padding: 40px 0;
}

.login__button {
    width: 100%;
    background: #000000;
    border-radius: 3px;
    margin: 157px 0 16px;
    padding: 13px 0 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
    transition: opacity 0.5s linear;
    border: none;
}

.login__button:hover {
    opacity: 0.6;
}
.login__button_disebled {
    opacity: 0.6;
}

.login__question {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #A0A0A0;
    margin: 0;
    align-self: center;
}

.login__link {
    text-decoration: none;
    padding-left: 6px;
    color: #000000;
    cursor: pointer;
    transition: opacity 0.5s linear;
}

.login__link:hover {
    opacity: 0.6;
    text-decoration: underline 2px #000000;
    font-weight: 500;
}

@media screen and (max-width: 1279px) {
    .login {
        padding: 232px 0;
    }
}

@media screen and (max-width: 767px) {
    .login {
        padding: 56px 0 30px;
        max-width: 260px;
        align-items: center;
    }

    .login__title {
        padding: 50px 0 80px;
    }

    .login__button {
        margin: 245px 0 16px;
        padding: 15px 0;
        font-size: 12px;
        line-height: 15px;
    }

    .login__question {
        font-size: 12px;
        line-height: 15px;
    }

    .login__link {
        padding-left: 7px;
    }
}