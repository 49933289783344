.account__link {
    display: flex;
    text-decoration: none;
    color: #000000;
    font-size: 14px;
    line-height: 16px;
    box-sizing: border-box;
    border-radius: 50px;
    outline: none;
    background-color: transparent;
    border: 2px solid #AAAAAA;
    cursor: pointer;
    transition: opacity 0.5s linear;
    padding: 6px 12px;
    margin-bottom: 5px;
    opacity: .6;
}

.account__link:hover {
    font-weight: 500;
    opacity: .2;
    border: 2px solid #000000;
}
.account__link:focus {
    font-weight: 500;
    opacity: .2;
    border: 2px solid #000000;
}
.account__link-icon {
    padding: 0 7px 0 0;
    width: 12px;
    height: 14px;

}

.account__link-text {
    text-decoration: none;
    font-weight: 400;
    margin: 0;
    text-align: right;
}

@media screen and (max-width: 1279px) {
    .account__link {
        margin-bottom: 0
    }
}

@media screen and (max-width: 767px) {
    .account__link {
        padding: 8px 12px;
    }

    .account__link-text {
        font-weight: 500;
    }
}