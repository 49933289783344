.nav__container {
    display: none;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    position: fixed;
}

.nav__container_opened {
    display: block;
}

.nav__menu {
    background: #FFFFFF;
    display: none;
    flex-direction: column;
    max-width: 520px;
    width: 100%;
    height: 100%;
    /* padding: 159px 0 90px; */
    justify-content: space-around;
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    align-items: center;
    transition: all 0.4s ease 0s;
}

@media screen and (max-width: 767px) {
    .nav__menu {
        max-width: 320px;
        /* padding: 144px 0 46px; */
    }
}

/* ------- оверлэй ----- */
.nav__overlay {
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
    background: #000000;
    width: 100vw;
    height: 100vh;
    opacity: 0.3;
}

.nav__links {
    display: grid;
}

.nav__menu_opened {
    display: flex;
}

.nav__close {
    background-color: transparent;
    border: none;
    outline: none;
    position: absolute;
    top: 23px;
    right: 23px;
    padding: 0;
    cursor: pointer;
    transition: opacity 0.5s linear;
}

.nav__close:hover {
    opacity: 0.2;
    
}
.nav__close:focus {
    outline: #AAAAAA;
    outline: 1.5px solid #AAAAAA;
}
.nav__close:active {
    outline: #AAAAAA;
    
}

@media screen and (max-width: 767px) {
    .nav__close {
        top: 13px;
        right: 13px;
    }
}

.nav__close-icon {
    width: 30px;
    height: 30px;
    background-color: transparent;
}

.nav__link {
    text-decoration: none;
    border: none;
    outline: none;
    color: #000000;
    font-weight: 400;
    padding-bottom: 28px;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
}

/* .nav__link:last-of-type {
    padding-bottom: 621px;
} */

.nav__link:hover {
    font-weight: 500;
    text-decoration: underline 2px #000000;
}
.nav__link:focus {
    font-weight: 500;
    text-decoration: underline 2px #000000;
}
.nav__link:active {
    font-weight: 500;
    text-decoration: underline 2px #000000;
}

.nav__link_active {
    font-weight: 500;
    text-decoration: underline 2px #000000;
}

@media screen and (max-width: 767px) {
    .nav__link {
        padding-bottom: 24px;
    }

    /* .nav__link:last-of-type {
        padding-bottom: 440px;
    } */
}

.profile-button:hover {
    font-weight: 500;
    opacity: .2;
}

.profile-icon {
    padding: 0 7px 0 0;

}

.profile-text {
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    text-align: right;
}