.popup {
    position: fixed;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    transition: all 0.4s ease 0s;
    opacity: 0;
    z-index: -1;
}

.popup_opened {

    opacity: 1;
    visibility: visible;
    z-index: 1;
}

.popup__container {
    background-color: #FFFFFF;
    max-width: 300px;
    min-height: 100px;
    padding: 25px 22px;
    border-radius: 10px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.popup__title {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    text-align: center;
}
.popup__close {
    background-image: url(../../images/delete_button.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: contain;
    width: 15px;
    height: 15px;
    border: none;
    padding: 0;
    position: absolute;
    top: -20px;
    right: -20px;
    cursor: pointer;
    transition: .3s;
}

.popup__close:hover {
    opacity: 0.6;
}
