.authorization__header {
    /* max-width: calc(100% - 70px*2); */
    padding: 18px 0;
    
    display: flex;
    justify-content: space-between;
    position: relative;
}
.authorization__header_style {
    background-color: #F3C1F8;
    padding: 18px 70px;
}

.header__box_check {
    display: flex;
    background-color: transparent;
    align-self: center;
    align-items: center;
    margin: 0;
    padding: 0;
}

.header__link_check {
    transition: opacity 0.5s linear;
    margin: 0;
    padding: 0;
    cursor: pointer;
    border: none;
    background-color: transparent;
    color: #000000;
    text-decoration: none;
}

.header__link_check:hover {
    text-decoration: underline 2px #000000;
    font-weight: 500;
}
.header__link_check:focus {
    text-decoration: underline 2px #000000;
    font-weight: 500;
    outline: none;
    border: none;
}

.header__movies {
    text-decoration: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    cursor: pointer;
    margin: 0;
    padding: 0 22px 5px 0;
}

.header__saved-movies {
    text-decoration: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    cursor: pointer;
    margin: 0;
    padding: 0 48px 5px 0;
}

@media screen and (max-width: 1279px) {
    .authorization__header {
        /* max-width: calc(100% - 30px*2);*/
         padding: 15px 30px;
    }
    .header__box_check {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .authorization__header {
        /* max-width: calc(100% - 14px*2);*/
        padding: 15px 14px;
    }

    .header__movies {
        font-size: 10px;
    }

    .header__saved-movies {
        font-size: 10px;
        padding: 5px 12px;
    }
}

/* Ссылки в Header в виде меню */

.header__menu {
    display: none;
    border: none;
    outline: none;
    cursor: pointer;
    transition: opacity 0.5s linear;
    background-color: transparent;
}

.header__menu:hover {
    opacity: 0.2;
}
.header__menu:focus {
    opacity: 0.2;
    border: 1.5px solid #AAAAAA;
    border-radius: 3px;
}
.header__menu-icon {
    width: 44px;
    height: 44px;
    margin: 0;
}

@media screen and (max-width: 1279px) {
    .header__menu {
        display: block;
        padding: 0;
        background-image: url(../../images/icon_menu.svg);
        width: 44px;
        height: 44px;
        background-size: 44px;
        background-repeat: no-repeat;
        background-position: center;
    }
}

@media screen and (max-width: 767px) {
    .header__menu {
        width: 40px;
        height: 43px;
        background-size: 40px;
    }

    .header__menu-icon {
        width: 40px;
        height: 43px;
    }
}