.profile__content {
    padding-bottom: 100px;
}

.profile__container {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 66px 0 0;
    position: relative;
}

.profile__name {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 50px;
    line-height: 58px;
    letter-spacing: -0.04em;
}

.profile__photo {
    width: 270px;
    height: 327px;
    overflow: hidden;
    object-fit: cover;
    position: absolute;
    top: 66px;
    right: 0;
    border-radius: 10px;
}

.profile__about {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    mix-blend-mode: normal;
    padding: 18px 0 26px;
    margin: 0;
}

.profile__text {
    max-width: 600px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    mix-blend-mode: normal;
    margin: 0;
}

.profile__links {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, minmax(min-content, max-content));
    gap: 20px;
    margin: 0;
    padding-top: 99px;
}

.profile__link {
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    transition: opacity 0.5s linear;
    cursor: pointer;

    text-decoration: none;
    color: #000000;
}
.profile__link:hover {
    opacity: 0.6;
    text-decoration: underline 2px #000000;
}
.profile__link:focus {
    opacity: 0.6;
    text-decoration: underline 2px #000000;
    outline: none;
}

@media screen and (max-width: 1279px) {
    .profile__content {
        padding-bottom: 90px;
    }

    .profile__name {
        font-size: 40px;
        line-height: 40px;
    }

    .profile__photo {
        width: 255px;
        height: 307px;
    }

    .profile__about {
        font-size: 12px;
        line-height: 18px;
        padding: 16px 0 20px;
    }

    .profile__text {
        max-width: 364px;
        font-size: 12px;
        line-height: 18px;
    }

    .profile__links {
    padding-top: 86px;
    }
}

@media screen and (max-width: 767px) {
    .profile__content {
        padding: 70px 14px 69px;
    }

    .profile__container {
        padding: 60px 0 0;
    }

    .profile__name {
        font-size: 30px;
        line-height: 36px;
        padding-top: 39px;
    }

    .profile__photo {
        width: 292px;
        height: 352px;
        position: static;
    }

    .profile__about {
        font-size: 11px;
        line-height: 16px;
        padding: 20px 0;
    }

    .profile__text {
        max-width: 292px;
        font-size: 11px;
        line-height: 16px;
    }

    .profile__links {
        padding-top: 40px;
        gap: 26px;
    }
}

