.portfolio {
    padding: 0 70px 108px;
}

.portfolio__container {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.portfolio__title {
    margin: 0;
    padding: 0 0 30px;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #A0A0A0;
    mix-blend-mode: normal;
}

.portfolio__project-container {
    margin: 0;
    padding: 20px 0 0;
    border-bottom: 1px solid #E8E8E8;
}

.portfolio__project-link {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    transition: opacity 0.5s linear;
    cursor: pointer;
    text-decoration: none;
    color: #000000
}

.portfolio__project-link:hover {
    font-weight: 500;
    outline: solid 2px #000000;
    border-radius: 5px;
    opacity: 0.6;
}
.portfolio__project-link:focus {
    font-weight: 500;
    outline: solid 2px #000000;
    border-radius: 5px;
    opacity: 0.6;
}

.portfolio__project-container:last-child {
    border-bottom: none;
}

.portfolio__project-container:first-child {
    border-bottom: none;
    padding: 0;
}

.portfolio__project {
    margin: 0;
    padding: 0 0 19px;
    display: block;
    font-weight: 400;
    font-size: 30px;
    line-height: 50px;
    letter-spacing: -0.04em;
}

.portfolio__project-symbol {
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 30px;
    line-height: 60px;
    letter-spacing: -0.04em;
}

@media screen and (max-width: 1279px) {
    .portfolio {
        padding: 0 50px 72px;
    }

    .portfolio__project {
        font-size: 28px;
    }
    .portfolio__project-symbol {
        font-size: 28px;
    }
}

@media screen and (max-width: 767px) {
    .portfolio {
        padding: 0 14px 70px;
    }
    .portfolio__title {
        padding-bottom: 20px;
        font-size: 14px;
    }
    .portfolio__project-container {
        border-bottom: 1px solid #E6E6E6;
    }
    .portfolio__project {
        font-size: 18px;
        line-height: 28px;
    }
    .portfolio__project-symbol {
        font-size: 18px;
        line-height: 28px;
    }
}