.content {
    padding: 110px 70px;
}

.content__text {
    display: grid;
}

.content__title {
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: -0.04em;
    margin: 0;
    padding-bottom: 24px;
    border-bottom: 1px solid #000000;
}

.content__columns {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: row;
    grid-auto-rows: 1fr;
    gap: 41px;
    list-style: none;
    padding: 70px 0 110px;
    margin: 0;
}

.content__subtitle {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.04em;
    padding: 0;
    margin: 0;
}

.content__discription {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    padding: 24px 20px 0 0;
    margin: 0;
}

.content__plan {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 4fr;
}

.content__time {
    padding: 9px 0;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    background-color: #F2F2F2;
}

.dark-theme {
    background-color: #000000;
    color: #FFFFFF;
}

.content__work {
    padding: 14px 0 0;
    margin: 0;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #A0A0A0;
}

@media screen and (max-width: 1279px) {
    .content {
        padding: 90px 50px;
    }

    .content__columns {
        padding-bottom: 93px;
        gap: 30px;
    }

    .content__discription {
        font-size: 12px;
        line-height: 18px;
        padding: 22px 0 0;
    }

    .content__work {
        font-weight: 400;
    }
}

@media screen and (max-width: 767px) {
    .content {
        padding: 70px 18px;
    }

    .content__title {
        font-size: 18px;
        line-height: 22px;
        padding-bottom: 28px;
    }

    .content__columns {
        padding: 60px 0;
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr;
        gap: 56px;
    }
    
    .content__subtitle {
        font-size: 18px;
        line-height: 22px;
    }

    .content__discription {
        font-size: 11px;
        line-height: 16px;
        padding-top: 16px;
    }

    .content__plan {
        grid-template-columns: 1fr 2fr;
    }

    .content__time {
        padding: 11px 0;
        font-size: 11px;
        line-height: 13px;
    }

    .content__work {
        padding-top: 10px;
        font-size: 11px;
        line-height: 13px;
    }
}